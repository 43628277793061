import { createI18n } from 'vue-i18n';
import getStartingLocale from './get-starting-locale';
import { LANG_STORAGE_KEY, LANGUAGES } from '../../constants';
import { pluralizeRu } from '../../functions';
import getSupportedLocales from './get-supported-locales';

export type Locale = 'ru' | 'kk' | 'en' | 'ka';

const supportedLocales = getSupportedLocales();
const defaultLocale = import.meta.env.VITE_DEFAULT_LOCALE || 'kk';
const fallbackLocale = import.meta.env.VITE_FALLBACK_LOCALE || 'ru';

export const SUPPORTED_LANGS =
	LANGUAGES.filter(lang => supportedLocales.includes(lang.code)) || LANGUAGES;

const i18n = createI18n({
	legacy: false,
	locale: getStartingLocale(defaultLocale),
	globalInjection: true,
	fallbackLocale: fallbackLocale,
	pluralRules: {
		ru: pluralizeRu
	},
	messages: SUPPORTED_LANGS.reduce<
		Record<string, Record<string, string | object>>
	>((acc, lang) => {
		if (!acc[lang.code]) {
			acc[lang.code] = lang.messages;
		}

		return acc;
	}, {})
});

/**
 * Изменение языка глобально и в хранилище клиента
 */
export function setLocaleToStorage(value: string) {
	localStorage.setItem(LANG_STORAGE_KEY, value);
}

export function needToDetermineLanguage() {
	return !localStorage.getItem(LANG_STORAGE_KEY);
}

export default i18n;
