import { v4 as uuidv4 } from 'uuid';

export const TABLET_SESSION = 'tablet-session';
export const TABLET_SESSION_PREFIX = 'tablet';

export const useSession = (name: string) => {
	const startSession = (prefix?: string) => {
		const uuid = uuidv4();
		const prefixName = prefix ? `${prefix}-${uuid}` : name;
		sessionStorage.setItem(name, `${prefixName}`);

		return uuid;
	};

	const endSession = () => {
		sessionStorage.removeItem(name);
	};

	const getSession = () => {
		return sessionStorage.getItem(name);
	};

	return {
		startSession,
		endSession,
		getSession
	};
};
