import { BaseApplication } from './base-application.provider';
import { SERVICES, VISIBLE_TOWNS } from '../../../../constants';
import { APP_RUNTIME } from '@/types/enums/common';
import type { ILoader, IService } from '@/types/interfaces/common';
import type { ITown } from '@/types/interfaces/common/address';
import type { TCallback } from '@/utils/plugins/di/types';
import { AppCustomConfig } from '@/types/interfaces/app';

export class CommonApplication extends BaseApplication {
	protected runtimeName: APP_RUNTIME;
	protected loader: ILoader = {
		on: () => {},
		off: () => {},
		handle: () => {}
	};

	constructor(runtimeName: APP_RUNTIME) {
		super();
		this.runtimeName = runtimeName;
	}

	isWebView(): boolean {
		return this.runtimeName === APP_RUNTIME.WEB_VIEW;
	}

	getRuntimeName(): APP_RUNTIME {
		return this.runtimeName;
	}

	setLoader(loader: ILoader): void {
		this.loader = loader;
	}

	showLoader(): void {
		this.loader.on();
	}

	hideLoader(): void {
		this.loader.off();
	}

	getNotificationChatLink(userId: string | null): Promise<string> {
		return Promise.resolve('');
	}

	findServiceById(id: string): IService | undefined {
		return Object.values(SERVICES).find(s => s.id === id);
	}

	getServiceById(id: string): IService {
		return this.findServiceById(id) || SERVICES.delivery;
	}

	getVisibleTowns(): ITown[] {
		return VISIBLE_TOWNS;
	}
	init(): void {}
	handleNavigationToBack(): void {}
	whenNavigationToBack(cb: TCallback): void {}
	cancelHandlingToBack(): void {}
	becomingActive(callback: TCallback): void {
		document.addEventListener('visibilitychange', () => {
			this.onVisibilityChange(callback);
		});
	}
	onVisibilityChange(callback: TCallback): void {
		if (document.visibilityState === 'visible') {
			callback();
		}
	}
	becomingHidden(): void {
		document.removeEventListener(
			'visibilitychange',
			this.onVisibilityChange as any
		);
	}
	openQR(): Promise<string> {
		return Promise.resolve('');
	}
	openExternalLink(url: string): void {
		window.open(url, '_blank');
	}
	getCustomConfig(): AppCustomConfig | undefined {
		return undefined;
	}
}
