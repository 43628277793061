import { application, auth } from './providers';
import { AuthServiceProvider } from './providers/auth';

const createProviders = (authProvider: AuthServiceProvider) => {
	const initedAuth = auth(authProvider);

	return {
		application,
		auth: initedAuth
	};
};

export { createProviders };
