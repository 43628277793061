export const createSafeExecutionProxy = <T extends object>(target: T): T => {
	return new Proxy(target, {
		get(target, prop) {
			if (prop in target) {
				const value = Reflect.get(target, prop);
				return typeof value === 'function'
					? (...args: any[]) => Reflect.apply(value, target, args)
					: value;
			} else {
				console.warn(`Method ${String(prop)} does not exist on`, target);
				return () => {};
			}
		}
	});
};
