<template>
	<div
		class="font-sirius px-4 text-center"
		:class="horizontal ? 'pt-6 pb-4' : 'py-6'"
	>
		<slot name="title">
			<div
				v-if="title"
				class="mb-2 text-lg font-bold"
				data-testid="action-title"
			>
				{{ title }}
			</div>
		</slot>

		<div class="mb-4 text-base font-medium" data-testid="action-text">
			<slot name="text"></slot>
		</div>

		<div class="flex" :class="buttonsContainerClasses">
			<UiButton
				v-if="$slots.confirm"
				:disabled="disabled"
				:type="danger ? 'error' : 'primary'"
				:class="buttonsClasses"
				data-testid="action-confirm"
				@click="confirm"
			>
				<slot name="confirm"></slot>
			</UiButton>

			<UiButton
				v-if="$slots.close"
				:class="buttonsClasses"
				data-testid="action-close"
				@click="close"
			>
				<slot name="close"></slot>
			</UiButton>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import UiButton from '@/components/ui/UiButton.vue';

interface Props {
	title?: string;
	horizontal?: boolean;
	reverse?: boolean;
	danger?: boolean;
	disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	title: '',
	horizontal: false,
	reverse: false,
	danger: false,
	disabled: false
});
const emit = defineEmits<{ (e: 'confirm'): void; (e: 'close'): void }>();

const buttonsContainerClasses = computed(() => {
	const classes = [];

	if (props.horizontal) {
		classes.push('-mr-2');
		classes.push(props.reverse ? 'flex-row-reverse' : 'flex-row');
	} else {
		classes.push('-mb-2');
		classes.push(props.reverse ? 'flex-col-reverse' : 'flex-col');
	}

	return classes.join(' ');
});

const buttonsClasses = computed(() => {
	return props.horizontal ? 'mr-2' : 'mb-2';
});

const confirm = () => emit('confirm');

const close = () => emit('close');
</script>
