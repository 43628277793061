import { client } from '../../default.client';
import getFilials from './get-filials';
import { JSON_API_HEADER } from '../../constants';
import type {
	ApiProxyResponse,
	ICreateOrderResponse
} from '../../types/interfaces';
import type { IFilialSettings } from '@/types/interfaces/navigator';
import type {
	ICreateDeliveryOrderPayload,
	ICreateExpressOrderPayload,
	ICreateTakeawayOrderPayload,
	ICreateVendorDeliveryOrderPayload,
	IOrderDetails,
	IOrderItem,
	IProductForApi
} from '@/types/interfaces/orders';
import type { IProduct } from '@/types/interfaces/menu';

export const takeaway = {
	async createDeliveryOrder(
		payload: ICreateDeliveryOrderPayload,
		idempotencyKey?: string
	) {
		const response = await client.post<ApiProxyResponse<ICreateOrderResponse>>(
			'takeaway/v1/delivery/order',
			payload,
			{ headers: { 'X-Idempotency-key': idempotencyKey } }
		);
		return response.data;
	},
	async createExpressOrder(
		payload: ICreateExpressOrderPayload,
		idempotencyKey?: string
	) {
		const response = await client.post<ApiProxyResponse<ICreateOrderResponse>>(
			'takeaway/v1/express/order',
			payload,
			{ headers: { 'X-Idempotency-key': idempotencyKey } }
		);
		return response.data;
	},
	async createMicromarketOrder(
		payload: ICreateTakeawayOrderPayload,
		idempotencyKey?: string
	) {
		const response = await client.post<ApiProxyResponse<ICreateOrderResponse>>(
			'takeaway/v1/micromarket/order',
			payload,
			{ headers: { 'X-Idempotency-key': idempotencyKey } }
		);
		return response.data;
	},
	async createOrder(
		payload: ICreateTakeawayOrderPayload,
		idempotencyKey?: string
	) {
		const response = await client.post<ApiProxyResponse<ICreateOrderResponse>>(
			'takeaway/v1/takeaway/order',
			payload,
			{ headers: { 'X-Idempotency-key': idempotencyKey } }
		);
		return response.data;
	},
	async createVendorDeliveryOrder(
		payload: ICreateVendorDeliveryOrderPayload,
		idempotencyKey?: string
	) {
		const response = await client.post<ApiProxyResponse<ICreateOrderResponse>>(
			'takeaway/v1/vendor-delivery/order',
			payload,
			{ headers: { 'X-Idempotency-key': idempotencyKey } }
		);
		return response.data;
	},
	async getFilialBanner(
		filialId: number,
		abort?: AbortController
	): Promise<ApiProxyResponse<IFilialSettings>> {
		const url = `takeaway/v2/filial/settings/banner/info/${filialId}?is_web_view=1`;

		const config = {
			headers: JSON_API_HEADER,
			signal: abort?.signal
		};

		const response = await client.get(url, { ...config });
		return response.data;
	},
	getFilials,
	async getFrequentProducts(
		filialId: number,
		abort?: AbortController
	): Promise<ApiProxyResponse<{ products: IProduct[] }>> {
		const config = {
			headers: JSON_API_HEADER,
			signal: abort?.signal
		};

		const response = await client.get(
			`takeaway/v1/filial/${filialId}/products/frequent/`,
			{
				...config,
				params: {
					limit: 20
				}
			}
		);
		return response.data;
	},
	async getOrderCookingTime(
		filialId: number,
		products: IProductForApi[]
	): Promise<
		ApiProxyResponse<{
			cooking_time?: number;
			is_high_cooking_time: boolean;
		}>
	> {
		const response = await client.post(
			'takeaway/order/cooking_time',
			{ filial_id: filialId, products },
			{ headers: JSON_API_HEADER }
		);
		return response.data;
	},
	async getOrderDetails(
		orderId: number
	): Promise<ApiProxyResponse<IOrderDetails>> {
		const response = await client.get(
			`takeaway/v2/user/order?order_id=${orderId}`,
			{ headers: JSON_API_HEADER }
		);
		return response.data;
	},
	async getOrders(
		page = 1,
		serviceType = 1,
		brandId?: string
	): Promise<ApiProxyResponse<IOrderItem[]>> {
		const params = {
			page,
			service_type: serviceType,
			brand_id: brandId
		};

		const response = await client.get('takeaway/v2/user/orders', {
			headers: JSON_API_HEADER,
			params
		});
		return response.data;
	}
};
