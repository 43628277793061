import { readonly } from 'vue';
import { encode, decode } from 'js-base64';
import { v4 as uuidv4 } from 'uuid';
import { KEYS } from './types';
import { parseToken } from './functions';
import { auth } from '@/api';
import i18n from '@/utils/plugins/i18n';
import { application } from '@/utils/plugins/di/providers';
import { fromEntries } from '@/utils/functions';
import { CLIENT_ID } from '@/utils/constants';
import { EmptyCb } from '@/types';

interface IState {
	trackId: string;
	accessToken: string;
	idempotencyKey: string;
}

const storage = window.localStorage;
const session = window.sessionStorage;
const authHandlers: EmptyCb[] = [];

const attach = (handler: EmptyCb) => {
	if (!authHandlers.includes(handler)) {
		authHandlers.push(handler);
	}
};

const detach = (handler: EmptyCb) => {
	const index = authHandlers.indexOf(handler);
	if (index >= 0) {
		authHandlers.splice(index, 1);
	}
};

const notify = async () => {
	const promises = [];

	for (const handler of authHandlers) {
		promises.push(Promise.resolve(handler()));
	}

	return Promise.all(promises);
};

const state: IState = {
	trackId: storage.getItem(KEYS.TRACK_ID) || '',
	accessToken: storage.getItem(KEYS.ACCESS_TOKEN) || '',
	idempotencyKey: session.getItem(KEYS.IDEMPOTENCY_KEY) || ''
};

const getAuthUrl = (): string => {
	return `${window.location.protocol}//${window.location.host}/auth`;
};

const isAuthorized = (): boolean => {
	return !!state.accessToken;
};

const getUserId = (): string | null => {
	const parsed = parseToken(state.accessToken);
	return parsed?.sub || null;
};

const setTrackId = (id: string): void => {
	state.trackId = id;
	storage.setItem(KEYS.TRACK_ID, id);
};

const setAccessToken = (token: string): void => {
	state.accessToken = token;
	storage.setItem(KEYS.ACCESS_TOKEN, token);
};

const setIdempotencyKey = (key: string): void => {
	state.idempotencyKey = key;
	session.setItem(KEYS.IDEMPOTENCY_KEY, key);
};

const logout = (): void => {
	state.trackId = '';
	state.accessToken = '';
	storage.removeItem(KEYS.TRACK_ID);
	storage.removeItem(KEYS.ACCESS_TOKEN);

	application.clearUserId();
};

const goToOauth = (): void => {
	setIdempotencyKey(uuidv4());
	const urlQueries = new URLSearchParams(window.location.search);
	const queries = fromEntries(urlQueries.entries());
	let redirectUrl = window.location.href.split('?')[0];
	redirectUrl = new URL(redirectUrl).pathname;

	const oauthUrl = new URL(import.meta.env.VITE_OAUTH);
	oauthUrl.searchParams.append('client_id', CLIENT_ID);
	oauthUrl.searchParams.append('redirect_uri', getAuthUrl());
	oauthUrl.searchParams.append('login-by-mail', 'false');
	oauthUrl.searchParams.append('lang', i18n.global.locale.value);
	oauthUrl.searchParams.append(
		'state',
		encode(JSON.stringify({ ...queries, route: redirectUrl }))
	);

	window.location.href = oauthUrl.href;
};

const goToOauthLogout = (): void => {
	window.location.href = import.meta.env.VITE_OAUTH_LOGOUT;
};

const parseOauthStateRoute = (state: any): string => {
	const data = JSON.parse(decode(state));
	const queries = { ...data };
	delete queries.route;

	let url = data.route;

	if (Object.keys(queries).length) {
		url += '?';
		const arr = [];

		for (const [key, val] of Object.entries(queries)) {
			arr.push(`${key}=${val}`);
		}

		url += arr.join('&');
	}

	return url;
};

const authWrapper = async <T>(
	promise: (...params: T[]) => Promise<boolean>
): Promise<boolean> => {
	const isExecuted = await promise();

	if (isExecuted) {
		await notify();

		const userId = getUserId();
		if (userId) {
			application.setUserId(userId);
		}
	}

	return isExecuted;
};

const authByTrackId = (id: string): Promise<boolean> => {
	return authWrapper(async () => {
		if (state.trackId === id) {
			return false;
		}

		const response = await auth.authByTrackUd(id);

		setTrackId(id);
		setAccessToken(response.access_token);
		return true;
	});
};

const authByCode = (code: string): Promise<boolean> => {
	return authWrapper(async () => {
		const response = await auth.authByCode(
			code,
			getAuthUrl(),
			state.idempotencyKey
		);
		setAccessToken(response.access_token);
		return true;
	});
};

const AuthService = {
	state: readonly(state) as IState,
	attach,
	detach,
	notify,
	isAuthorized,
	getUserId,
	getAuthUrl,
	logout,
	goToOauth,
	goToOauthLogout,
	parseOauthStateRoute,
	authByTrackId,
	authByCode,
	setAccessToken,
	getAccessToken: () => state.accessToken
};

export { AuthService };
