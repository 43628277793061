export const COUNTRIES = {
	ge: {
		name: 'country.ge',
		code: 'ge',
		currency: 'GEL'
	},
	kz: {
		name: 'country.kz',
		code: 'kz',
		currency: 'KZT'
	}
} as const;

export const getCountryByDomain = () => {
	const domain = window.location.hostname;
	const countryCode = domain.split('.').pop() as keyof typeof COUNTRIES;
	return COUNTRIES[countryCode] || COUNTRIES.kz;
};
