import { chocofoodClient } from '../chocofood.client';
import getAddressSuggestionsAdapter from '../adapters/chocofood/get-address-suggestions.adapter';
import getReverseGeoAdapter from '../adapters/chocofood/get-reverse-geo.adapter';
import i18n from '@/utils/plugins/i18n';
import type {
	IAddressSuggestion,
	IReverseGeocoding
} from '@/types/interfaces/chocofood';
import type { ICoordinates } from '@/types/interfaces/common';

export const chocofood = {
	async getSuggestions(address: string): Promise<IAddressSuggestion[]> {
		const params = {
			address,
			limit: 3
		};

		const response = await chocofoodClient.get('geo-service/suggestions/', {
			params,
			headers: {
				locale: i18n.global.locale.value
			}
		});

		return getAddressSuggestionsAdapter(response.data.data);
	},
	async getReverseGeocode(
		coordinates: ICoordinates
	): Promise<IReverseGeocoding[]> {
		const params = {
			lat: coordinates.latitude,
			long: coordinates.longitude
		};

		const response = await chocofoodClient.get('geo-service/reverse-geocode/', {
			params,
			headers: {
				locale: i18n.global.locale.value
			}
		});

		return getReverseGeoAdapter(response.data.data);
	}
};
