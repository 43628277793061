import { WebApplication } from './web-application.provider';
import { WebViewApplication } from './webview-application.provider';
import { ReactNativeApplication } from './react-native-application.provider';
import { BaseApplication } from './base-application.provider';

export type ApplicationType = WebApplication &
	WebViewApplication &
	ReactNativeApplication &
	BaseApplication;

const getApplication = () => {
	if (window.RahmetApp) {
		return new WebViewApplication();
	}

	if (window.ReactNativeWebView) {
		return new ReactNativeApplication();
	}

	return new WebApplication();
};

export const application = getApplication() as ApplicationType;
