type RoundingStrategy = 'none' | 'down' | 'up';

/**
 * down - Math.floor
 * up - Math.ceil
 * none - нет округления
 *
 * @example
 * round(1.5, 'down') // 1
 * round(1.5, 'up') // 2
 * round(1.5, 'none') // 1.5
 */
export default function (value: number, type?: RoundingStrategy): number {
	const envStrategy = import.meta.env
		.VITE_ROUNDING_STRATEGY as RoundingStrategy;
	const strategy = type || envStrategy || 'down';

	if (strategy === 'none') {
		return value;
	}

	const method = strategy === 'down' ? 'floor' : 'ceil';
	return Math[method](value);
}
