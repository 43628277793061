import type { App } from 'vue';
import { createProviders } from './index';
import { ProvidersKey } from '@/types/symbols';
import { AuthServiceProvider } from './providers/auth';

const createProvidersPlugin = (authProvider: AuthServiceProvider) => {
	const providers = createProviders(authProvider);
	return {
		install: (app: App) => {
			providers.application.init();
			app.provide(ProvidersKey, providers);
		}
	};
};

export { createProvidersPlugin };
