import type { IImageProxyOptions } from './default-preset';

const WHITE_LIST = [
	/rahmet(dev)?\.object\.pscloud\.io/,
	/parstore\.object\.pscloud\.io/
];

/**
 * Имеется ли применимое регулярное выражение
 */
const containsWhiteListUrl = (url: string) => {
	return WHITE_LIST.some(re => re.test(url));
};

/**
 * Получение формата картинки
 */
const getWishedFormat = (as: string): string => (as ? `@${as}` : '');

/**
 * В проекте используются несколько бакетов: rahmet и parstore.
 * Надо динамически хендлить url в зависимости от бакета
 */
export const getSource = (options: IImageProxyOptions): string => {
	let source = options.placeholder || '';

	if (options.url) {
		// убрать для отличных url получение баскета
		if (!containsWhiteListUrl(options.url)) {
			source = options.url;
		} else {
			const urlWrapper = new URL(options.url);
			const parts = [];

			parts.push(import.meta.env.VITE_IMAGE_PROXY);
			parts.push('sig');

			if (options.size) {
				parts.push(options.size);
			}

			parts.push('plain/s3:/');
			parts.push(urlWrapper.hostname.split('.')[0]);
			parts.push(urlWrapper.pathname);

			source = parts.join('/') + getWishedFormat(options.as || '');
		}
	}

	return source;
};
