import type { IService } from '@/types/interfaces/common';

type IServices = Record<string, IService>;

const SERVICES: IServices = {
	takeaway: {
		id: 'takeaway',
		name: 'services.takeaway',
		typeId: 1
	},
	express: {
		id: 'express',
		name: 'services.express',
		typeId: 3
	},
	delivery: {
		id: 'delivery',
		name: 'services.delivery',
		typeId: 4
	}
};

export default SERVICES;
