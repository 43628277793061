import { client } from '../default.client';
import type {
	IGetFilialResponse,
	ITerminalResponse
} from '../types/interfaces/get-filial-response';
import type { ApiProxyResponse } from '../types/interfaces';
import type { ICoordinates } from '@/types/interfaces/common';

interface IPayload {
	filial_id: number;
	coordinates?: ICoordinates | null;
	brand_id?: number;
}

export const locations = {
	async getFilial(payload: IPayload, abort?: AbortController) {
		const headers = payload.coordinates
			? {
					'X-Latitude': payload.coordinates.latitude,
					'X-Longitude': payload.coordinates.longitude
				}
			: {};

		const response = await client.get<ApiProxyResponse<IGetFilialResponse>>(
			`takeaway/locations/card/terminal/${payload.filial_id}`,
			{
				params: {
					include: 'takeaway,express,delivery,extra',
					brand_id: payload.brand_id
				},
				headers,
				signal: abort?.signal
			}
		);
		return response.data;
	},
	async getCleanFilial(filialId: number) {
		const response = await client.get<
			ApiProxyResponse<Omit<IGetFilialResponse, 'included'>>
		>(`locations/v2/locations/card/terminal/${filialId}`, {
			params: {
				include: 'extra'
			}
		});
		return response.data;
	},
	async getTerminal(
		filialId: string | number
	): Promise<ApiProxyResponse<ITerminalResponse>> {
		const response = await client.get<ApiProxyResponse<ITerminalResponse>>(
			`terminals/terminals/${filialId}`
		);

		return response.data;
	}
};
