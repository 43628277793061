import dayjs from 'dayjs';
import('dayjs/locale/ru');
import('dayjs/locale/kk');
import('dayjs/locale/ka');
import('dayjs/locale/en');

type Language = 'ru' | 'kk' | 'ka' | 'en';

const isLanguageAvailable = (value: string): value is Language => {
	return ['ru', 'kk', 'ka', 'en'].includes(value);
}

const getDateWithLocale = (
	date: string,
	locale: string,
	format = 'D MMMM HH:mm'
) => {
	if (isLanguageAvailable(locale)) {
		return dayjs(date).locale(locale).format(format);
	}
};

export { getDateWithLocale };
