import type {
	IAddressSuggestion,
	IGetAddressSuggestionResponse
} from '@/types/interfaces/chocofood';

export default function (
	response: IGetAddressSuggestionResponse[]
): IAddressSuggestion[] {
	return response.map(el => ({
		street: el.title,
		coordinates: {
			latitude: +el.geometry.location.lat.toFixed(6),
			longitude: +el.geometry.location.long.toFixed(6)
		}
	}));
}
