import { authorize as nativeAuthorize } from 'choco-app';
import {
	AUTH_BY_CODE_ERROR,
	AUTH_BY_TRACK_ERROR,
	CLIENT_ID
} from '@/utils/constants';

export interface AuthServiceProvider {
	getAuthUrl(): string;
	goToOauth(): void;
	authByTrackId(trackId: string): Promise<boolean>;
	authByCode(code: string): Promise<boolean>;
	getUserId(): string | null;
}

abstract class AuthProvider {
	constructor(protected authServiceProvider: AuthServiceProvider) {}

	abstract authorize(): Promise<boolean | void>;
}

class WebAuthProvider extends AuthProvider {
	authorize(): Promise<void> {
		return new Promise(this.authServiceProvider.goToOauth);
	}
}

class ReactNativeAuthProvider extends AuthProvider {
	authorize(): Promise<void> {
		return new Promise(this.authServiceProvider.goToOauth);
	}
}

class ChocoAuthProvider extends AuthProvider {
	async authorize(): Promise<boolean> {
		try {
			const code = await nativeAuthorize(
				CLIENT_ID,
				'code',
				this.authServiceProvider.getAuthUrl()
			);

			if (code) {
				return await this.authServiceProvider.authByCode(code);
			}

			return Promise.reject(new Error(AUTH_BY_CODE_ERROR));
		} catch (error: any) {
			if (error.message === 'NOT_SUPPORTED') {
				const trackId = await nativeAuthorize(CLIENT_ID, 'trackId');

				if (trackId) {
					return await this.authServiceProvider.authByTrackId(trackId);
				}

				return Promise.reject(new Error(AUTH_BY_TRACK_ERROR));
			}

			return Promise.reject(error);
		}
	}
}

const auth = (authServiceProvider: AuthServiceProvider): AuthProvider => {
	if (window.RahmetApp) {
		return new ChocoAuthProvider(authServiceProvider);
	}

	if (window.ReactNativeWebView) {
		return new ReactNativeAuthProvider(authServiceProvider);
	}

	return new WebAuthProvider(authServiceProvider);
};

export {
	auth,
	AuthProvider,
	WebAuthProvider,
	ReactNativeAuthProvider,
	ChocoAuthProvider
};
