import { CommonApplication } from './common-application.provider';
import { telegram } from '@/api';
import { APP_RUNTIME } from '@/types/enums/common';

export class WebApplication extends CommonApplication {
	constructor() {
		super(APP_RUNTIME.WEB);
	}

	override getNotificationChatLink(userId: string | null): Promise<string> {
		const telegramBotUrl = import.meta.env.VITE_TELEGRAM_BOT;
		return telegram
			.getNotificationChat()
			.then(() => telegramBotUrl)
			.catch(error => {
				const statusCode = error.response.data.error_code;
				if (statusCode === 404) {
					return `${telegramBotUrl}?start=${userId}`;
				}
				throw error;
			});
	}

	override openExternalLink(url: string): void {
		window.open(url, '_blank');
	}
}
