import type { Router } from 'vue-router';
import { metrikaScript, metrikaNoScript } from './yandex-metrika.constants';
import type { EmptyCb } from '@/types';

const appEnv = import.meta.env.VITE_ENV;
const counterId = import.meta.env.VITE_YANDEX_KEY;

export const YandexMetrikaService = {
	init(router: Router) {
		// Inject only in production
		if (appEnv !== 'production') return;

		// Prevent multiple script injections
		if (window.ym) return;
		if (document.getElementById('yandex-metrika-script')) return;

		const script = document.createElement('script');
		script.id = 'yandex-metrika-script';
		script.type = 'text/javascript';
		script.innerHTML = metrikaScript(counterId);
		document.head.appendChild(script);

		const noscript = document.createElement('noscript');
		noscript.innerHTML = metrikaNoScript(counterId);
		document.body.appendChild(noscript);

		router.afterEach((to, from) => {
			if (to.path !== from.path) {
				this.hit(to.fullPath);
			}
		});
	},
	afterInit(callback: EmptyCb) {
		if (window.ym) {
			return callback();
		}

		document.addEventListener(`yacounter${counterId}inited`, callback);
	},
	hit(url?: string) {
		if (!window.ym) {
			return;
		}

		window.ym(counterId, 'hit', url);
	},
	setUserId(userId: string) {
		if (!window.ym) {
			return;
		}

		window.ym(counterId, 'userParams', { UserId: userId });
	}
};
