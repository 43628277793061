import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import { getKeyByValue } from '@/utils/functions';
import { WEEK_DAYS } from '@/utils/constants';
import type { IRushHour } from '@/types/interfaces/common';

dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);

const timeFormat = 'HH:mm';

/**
 * Проверяет текущее время на час пик
 */
export default function (rush?: IRushHour | null): boolean {
	if (!rush) {
		return false;
	}

	const currentWeekday = dayjs().isoWeekday();
	const weekdayKey = getKeyByValue(WEEK_DAYS, currentWeekday) || '';
	const weekdayRush = rush[weekdayKey];

	if (weekdayRush && weekdayRush.rush_hour) {
		const now = dayjs();

		for (const item of weekdayRush.rush_hour) {
			const rushFrom = dayjs(item.from, timeFormat);
			const rushTo = dayjs(item.to, timeFormat);

			if (now.isBetween(rushFrom, rushTo)) {
				return true;
			}
		}
	}

	return false;
}
