import { client } from '../default.client';
import type {
	ApiProxyResponse,
	ApiProxyJSONApiResponse
} from '../types/interfaces';
import type {
	PrivateRewardBalancePayload,
	PrivateRewardBalanceRaw
} from '@/types/interfaces/balance';

interface CurrencyAmount {
	KZT: number;
}

interface Balance {
	bonus: CurrencyAmount;
	money: CurrencyAmount;
}

const balanceAdapter = (
	data: ApiProxyJSONApiResponse<PrivateRewardBalanceRaw>
) => {
	return {
		...data,
		attributes: {
			...data.attributes,
			max_bonus_usage_percentage:
				data.attributes.rule.max_bonus_usage_percentage
		}
	};
};

export const balance = {
	async getPrivateRewardBalance(payload: PrivateRewardBalancePayload) {
		const response = await client.get<{
			data: ApiProxyJSONApiResponse<PrivateRewardBalanceRaw>;
		}>('/ladder-go/api/v1/balance/calculate-info', { params: payload });

		return balanceAdapter(response.data.data);
	},
	async getUserBalance() {
		const response =
			await client.get<ApiProxyResponse<Balance>>('/balance/user');
		return response.data;
	}
};
