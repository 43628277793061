import { APP_RUNTIME } from '@/types/enums/common';
import type { AppCustomConfig } from '@/types/interfaces/app';
import type { TCallback } from '@/utils/plugins/di/types';

export abstract class BaseApplication {
	protected abstract runtimeName: APP_RUNTIME;

	abstract becomingActive(callback: TCallback): void;
	abstract becomingHidden(): void;
	abstract handleNavigationToBack(): void;
	abstract whenNavigationToBack(cb: TCallback): void;
	abstract cancelHandlingToBack(): void;
	abstract openQR(): Promise<string>;
	abstract openExternalLink(url: string): void;
	abstract getCustomConfig(): AppCustomConfig | undefined;
	abstract init(): void;
}
