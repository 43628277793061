import { InjectionKey } from 'vue';
import { ILoader, INotification } from './interfaces/common';
import { IApplication, Providers } from '@/utils/plugins/di/types';
import { IDeliveryPlugin } from '@/utils/plugins/delivery/types';
import { IMenuService } from '@/services/menu';
import { ComponentPublicInstance } from 'vue';

const NotificationKey: InjectionKey<
	ComponentPublicInstance<unknown, INotification>
> = Symbol('$notification');
const ProvidersKey: InjectionKey<Providers> = Symbol('providers');
const LoaderKey: InjectionKey<ComponentPublicInstance<unknown, ILoader>> =
	Symbol('$loader');
const DeliveryKey: InjectionKey<IDeliveryPlugin> = Symbol('delivery');
const MenuServiceKey: InjectionKey<IMenuService> = Symbol('menu-service');
const ThousandsSeparatorKey: InjectionKey<(value: number) => string> = Symbol(
	'$thousandsSeparator'
);

export {
	NotificationKey,
	ProvidersKey,
	LoaderKey,
	DeliveryKey,
	MenuServiceKey,
	ThousandsSeparatorKey
};
