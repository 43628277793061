import App from './App.vue';
import { createApp } from 'vue';
import UiBottomSheet from '@/components/ui/UiBottomSheet.vue';
import UiModal from '@/components/ui/UiModal.vue';
import { YandexMetrikaService } from '@/services/yandex-metrika';
import { SentryService } from '@/services/sentry';
import { VendorAnalytics } from '@/services/vendor-analytics';
import { AuthService } from '@/services/auth';
import { ClientService } from '@/services/client';
import { clickOutside, onlyLetter, onlyNumber } from '@/utils/directives';
import { loaderPlugin } from '@/utils/plugins/loader';
import { notificationPlugin } from '@/utils/plugins/notification';
import { imageProxyPlugin } from '@/utils/plugins/image-proxy';
import { deliveryPlugin } from './utils/plugins/delivery';
import { createProvidersPlugin } from '@/utils/plugins/di/plugin-installer';
import { thousandSeparatorPlugin } from '@/utils/plugins/thousand-separator';
import router from './router';
import i18n from './utils/plugins/i18n';
import currency from './utils/plugins/currency';
import './assets/styles/styles.scss';
import { setupInterceptors } from './api/default.client';
import { setupCFInterceptors } from './api/chocofood.client';
import { createProviders } from './utils/plugins/di';

const app = createApp(App);

const providers = createProviders(AuthService);
setupInterceptors(AuthService, SentryService, ClientService, providers);
setupCFInterceptors(SentryService);

app.use(router);
app.use(i18n);
app.use(loaderPlugin);
app.use(notificationPlugin);
app.use(imageProxyPlugin);
app.use(createProvidersPlugin(AuthService));
app.use(deliveryPlugin);
app.use(thousandSeparatorPlugin);
app.use(currency);

const config = providers.application.getCustomConfig();
if (config?.analytics) {
	VendorAnalytics.init(config.analytics);
}

YandexMetrikaService.init(router);
SentryService.init(app, router);

app.directive('click-outside', clickOutside);
app.directive('only-number', onlyNumber);
app.directive('only-letter', onlyLetter);

app.component('UiBottomSheet', UiBottomSheet);
app.component('UiModal', UiModal);

app.mount('#app');
