import { RouteLocationNormalizedLoaded, NavigationGuardNext } from 'vue-router';
import { ClientService } from '@/services/client';
import { application } from '@/utils/plugins/di/providers';

export default function (
	to: RouteLocationNormalizedLoaded,
	from: RouteLocationNormalizedLoaded,
	next: NavigationGuardNext
) {
	const coordinates = ClientService.state.coordinates;

	if (coordinates) {
		return next();
	} else {
		application.showLoader();

		return ClientService.initCoordinates()
			.then(inited => {
				const requiresGeo = to.meta?.requiresGeo;

				if (requiresGeo && !inited) {
					return next({ name: 'Home' });
				}

				return next();
			})
			.finally(() => application.hideLoader());
	}
}
