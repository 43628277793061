import { ClientService } from '@/services/client';
import { ALL_TOWNS } from '@/utils/constants';
import type { ICfUserAddress } from '@/types/interfaces/chocofood';

const DEFAULT_ADDRESS: ICfUserAddress = {
	apartment: '',
	coordinates: {
		longitude: 0,
		latitude: 0
	}
};

const wrapper = (defaultTown: string): (() => ICfUserAddress) => {
	const town = ClientService.getTownById(ClientService.state.townId);

	return () => ({
		...DEFAULT_ADDRESS,
		city: town ? town.title : defaultTown
	});
};

export const getDefaultAddress = wrapper(ALL_TOWNS[0].title);
