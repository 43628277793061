import { client } from '../default.client';
import { JSON_API_HEADER } from '../constants';
import {
	menuCategoriesAdapter,
	productAttrAdapter,
	productsAdapter
} from '../adapters/menu';
import type { ApiProxyResponse, IPayloadProduct } from '../types/interfaces';
import type { AxiosResponse } from 'axios';
import type {
	AdditiveGroupWithSelected,
	BarcodeProductRaw,
	IAdditiveGroup,
	IBarcodeProduct,
	IProduct,
	MenuCategories,
	ProductAttrRaw,
	ProductSize
} from '@/types/interfaces/menu';

interface IPayload {
	filial_id: number;
	products: IPayloadProduct[];
}

interface Response {
	stopped_products: number[];
	stopped_additives: number[];
}

interface ProductImage {
	id: number;
	image_url: string;
}

interface AdditiveCategoriesItem {
	id: number;
	additives: AdditivesItem[];
}
interface AdditivesItem {
	id: number;
	name: string;
	price: number;
	is_stopped: boolean;
	is_hidden: boolean;
	is_selected_by_default: boolean;
}

interface ProductWithAdditivesCommon {
	id: number;
	name: string;
	price: number;
	description: string | null;
	is_stopped: boolean;
	images: ProductImage[];
	additive_categories: AdditiveCategoriesItem[];
	sizes: ProductSize[] | null;
	promo_limit: number | null;
	promo_day_limit: number | null;
}
interface ProductWithAdditivesRaw extends ProductWithAdditivesCommon {
	old_price: number | null;
	reward_id: string | null;
	discount_amount: string | null;
}

interface ProductWithAdditive extends ProductWithAdditivesCommon {
	promo_id: string | null;
	discount: string | null;
	promo_price: number | null;
}

const additivesAdapter = (
	res: ApiProxyResponse<AdditiveGroupWithSelected>
): ApiProxyResponse<AdditiveGroupWithSelected> => {
	res.data.additive_groups = res.data.additive_groups.map(group => {
		group.relationships.forEach(add => {
			add.attributes.min = add.attributes.min || 0;
			add.attributes.max = add.attributes.max || group.attributes.max;
		});

		return group;
	});

	return res;
};

const getFavourite = async (
	route: string,
	filialId: number,
	abort?: AbortController
) => {
	const config = {
		headers: JSON_API_HEADER,
		signal: abort?.signal
	};

	const response = await client.get<
		ApiProxyResponse<IProduct<ProductAttrRaw>[]>
	>(route, {
		...config,
		params: {
			filial_id: filialId,
			limit: 20
		}
	});

	return {
		...response.data,
		data: productsAdapter(response.data.data)
	};
};

export const menu = {
	async checkProducts(payload: IPayload): Promise<ApiProxyResponse<Response>> {
		const response = await client.post(
			'menu/v2/stop_list/order_items/check',
			payload
		);

		return response.data;
	},
	async getFavouriteProducts(filialId: number, abort?: AbortController) {
		return getFavourite('menu/v3/popular/products', filialId, abort);
	},
	async getFavouriteProductsAuth(filialId: number, abort?: AbortController) {
		return getFavourite('menu/v3/favourite/products', filialId, abort);
	},
	async getMenuCategories(
		filialId: number,
		abort?: AbortController
	): Promise<ApiProxyResponse<MenuCategories>> {
		const config = {
			headers: JSON_API_HEADER,
			signal: abort?.signal
		};

		const response = await client.get(
			`menu/v2/category/products?filial_id=${filialId}`,
			{ ...config }
		);

		return {
			...response.data,
			data: menuCategoriesAdapter(response.data.data)
		};
	},
	getProductAdditives(
		filialId: number,
		productId: number,
		serviceType: number,
		abort?: AbortController
	): Promise<ApiProxyResponse<AdditiveGroupWithSelected>> {
		return client
			.get<ApiProxyResponse<IAdditiveGroup[]>>('menu/v2/product/additives', {
				params: {
					filial_id: filialId,
					product_id: productId,
					service_type: serviceType
				},
				headers: JSON_API_HEADER,
				signal: abort?.signal
			})
			.then(response => {
				return additivesAdapter({
					...response.data,
					data: {
						additive_groups: response.data.data
					}
				});
			})
			.catch(error => {
				throw error;
			});
	},
	getProductAdditivesAuth(
		filialId: number,
		productId: number,
		serviceType: number,
		abort?: AbortController
	): Promise<ApiProxyResponse<AdditiveGroupWithSelected>> {
		return client
			.get<ApiProxyResponse<AdditiveGroupWithSelected>>(
				'menu/v2/favourite-product/additives',
				{
					params: {
						filial_id: filialId,
						product_id: productId,
						service_type: serviceType
					},
					headers: JSON_API_HEADER,
					signal: abort?.signal
				}
			)
			.then(response => additivesAdapter(response.data))
			.catch(error => {
				throw error;
			});
	},
	async getProductByBarcode(
		filial_id: number,
		barcode: string
	): Promise<AxiosResponse<IBarcodeProduct>> {
		const response = await client.get<AxiosResponse<BarcodeProductRaw>>(
			`menu/v3/product-by-barcode?filial_id=${filial_id}&barcode=${barcode}`
		);

		return {
			...response.data,
			data: {
				...productAttrAdapter(response.data.data),
				id: response.data.data.id
			}
		};
	},
	async getProducts(
		filialId: number,
		products: IPayloadProduct[]
	): Promise<ApiProxyResponse<ProductWithAdditive[]>> {
		const params = {
			filial_id: filialId,
			products: products
		};
		const response = await client.get<
			ApiProxyResponse<ProductWithAdditivesRaw[]>
		>(`menu/v2/products/with/additives/`, {
			params
		});
		return {
			...response.data,
			data: response.data.data.map(product => ({
				...productAttrAdapter({
					...product,
					image_url: product.images[0]?.image_url || '',
					cooking_time: 0,
					is_favorite: false,
					has_additive_categories: product.additive_categories.length > 0
				}),
				id: product.id,
				images: product.images,
				additive_categories: product.additive_categories
			}))
		};
	},
	async getRecommendedProducts(
		filialId: number,
		productIds: number[]
	): Promise<IProduct[]> {
		const response = await client.get('menu/v3/cross-sell/products', {
			params: { filial_id: filialId, products: productIds },
			headers: JSON_API_HEADER
		});
		return productsAdapter(response.data.data);
	}
};
