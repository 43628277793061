import { client } from '../default.client';
import { JSON_API_HEADER } from '../constants';
import type { ApiProxyResponse } from '../types/interfaces';
import type { IPromo, IPromoProduct } from '@/types/interfaces/common';
import type { Subsidy } from '@/types/interfaces/menu';

interface PromosResponse {
	data: IPromo[];
	included?: IPromoProduct[];
}

export const menuPromos = {
	async getPromos(
		filialId: number,
		userId: string | null,
		abort?: AbortController
	) {
		const params = {
			'filter[filial_id]': filialId,
			'filter[onlyActive]': true,
			include: 'products',
			user_id: userId
		};

		const config = {
			headers: JSON_API_HEADER,
			signal: abort?.signal,
			params
		};

		const response = await client.get<PromosResponse>(
			'menu-promos/api/v2/promos',
			{ ...config }
		);

		return response.data;
	},
	async getSubsidiesByFilial(filialId: number): Promise<Subsidy> {
		const params = {
			'terminal_ids[]': filialId,
			only_active: true
		};

		const config = {
			headers: JSON_API_HEADER,
			params
		};

		const response = await client.get<ApiProxyResponse<Subsidy[]>>(
			'menu-promos/v1/promos/subsidies',
			{
				...config
			}
		);

		return response.data.data[0];
	}
};
