import { readonly, computed } from 'vue';
import { IState, state } from './state';
import { KEYS } from './keys';
import { NEAR_CATEGORY, RECENT_CATEGORY } from './constants';
import { formatSchedule, parseLocationTerminal } from './functions';
import { takeaway, locations } from '@/api';
import { application } from '@/utils/plugins/di/providers';
import type {
	ICoordinates,
	IPaginationWrapper
} from '@/types/interfaces/common';
import type {
	ICategoryWithFilials,
	IFilial,
	IFilialSettings
} from '@/types/interfaces/navigator';

interface ICallbacks {
	onStart: () => unknown;
	onFinish: () => unknown;
	onError: (error: Error) => unknown;
}

const MAX_FAR_DISTANCE = 1500;
const WARNING_DISTANCE_LIMIT = 50000;

/**
 * Все категории
 */
const allCategories = computed(() => [
	state.promoFilialsCategory,
	state.nearFilialsCategory,
	state.popularFilialsCategory,
	state.recentFilialsCategory,
	state.bahandiFilialsCategory,
	state.kikisFilialsCategory,
	state.sfFilialsCategory
]);

/**
 * Основные категории (с картинкой)
 */
const mainCategories = computed(() => {
	return allCategories.value
		.filter(item => item.image && item.filials.hasResults())
		.sort((a, b) => (a.position || 0) - (b.position || 0));
});

/**
 * Назначение филиалов категориям
 */
const setFilials = (data: {
	name: string;
	filials: IPaginationWrapper<IFilial>;
}) => {
	const category = allCategories.value.find(category => {
		return category.name === data.name;
	});

	if (category) {
		category.filials.addResults(data.filials);
	}
};

/**
 * Очистка всех филиалов
 */
const clearAllFilials = () => {
	allCategories.value.forEach(category => category.filials.clearResults());
};

/**
 * Получение филиалов по категории
 */
const fetchFilials = (
	data: {
		name: string;
		payload: any;
		coordinates: ICoordinates | null;
		serviceId?: string;
		townId?: number;
	},
	abort?: AbortController
) => {
	return takeaway
		.getFilials(
			{
				include: 'promo',
				service: data.serviceId,
				town: data.townId,
				coordinates: data.coordinates,
				...data.payload
			},
			abort
		)
		.then(response => setFilials({ name: data.name, filials: response }));
};

/**
 * Загрузить больше филиалов по категории
 */
const loadMoreFilials = (
	category: ICategoryWithFilials<IFilial>,
	coordinates: ICoordinates | null,
	serviceId: string,
	townId: number,
	cb: ICallbacks = {
		onStart: () => {},
		onError: () => {},
		onFinish: () => {}
	}
) => {
	if (state.hasError) {
		return;
	}

	if (category.filials.page < category.filials.lastPage) {
		cb.onStart();

		fetchFilials({
			name: category.name,
			payload: {
				...category.payload,
				page: category.filials.page + 1
			},
			coordinates,
			serviceId,
			townId
		})
			.catch((error: Error) => {
				state.hasError = true;
				cb.onError(error);
			})
			.finally(() => cb.onFinish());
	}
};

/**
 * Инициализация филиалов на главной
 */
const initAllFilials = (
	coordinates: ICoordinates | null,
	isAuthorized: boolean,
	serviceId: string,
	townId: number,
	abort?: AbortController
) => {
	const promises = [];

	for (const category of allCategories.value) {
		// prettier-ignore
		if (
			(category.name === NEAR_CATEGORY.name && !coordinates) ||
			(category.name === RECENT_CATEGORY.name && !isAuthorized) ||
			category.filials.hasResults()
		) {
			continue;
		}

		promises.push(
			fetchFilials(
				{
					name: category.name,
					payload: {
						...category.payload,
						page: 1
					},
					coordinates,
					serviceId,
					townId
				},
				abort
			)
		);
	}

	return Promise.allSettled(promises);
};

/**
 * Получение параметров филиала
 */
const fetchFilialSettings = async (
	id: number,
	abort?: AbortController
): Promise<IFilialSettings> => {
	return takeaway.getFilialBanner(id, abort).then(response => response.data);
};

/**
 * Получение данных филиала
 */
const fetchFilial = async (
	id: number,
	coordinates: ICoordinates | null,
	abort?: AbortController,
	force = false
) => {
	const settings = await fetchFilialSettings(id, abort);
	const sameFilialExists = state.recentFilial?.terminal_id === id;
	const config = application.getCustomConfig();

	if (state.recentFilial && sameFilialExists && !force) {
		state.recentFilial.banner_info = settings.banner;
	} else {
		const response = await locations.getFilial(
			{ filial_id: id, coordinates, brand_id: config?.brand_id },
			abort
		);

		if (response.data.location.extra?.schedule) {
			response.data.location.extra.schedule = formatSchedule(
				response.data.location.extra.schedule,
				settings.relationships.filial_settings.close_in_advance_minutes
			);
		}

		state.recentFilial = parseLocationTerminal(id, response, {
			banner_info: settings.banner
		});
	}

	return {
		filial: state.recentFilial,
		settings
	};
};

/**
 * Далеко ли дистанция
 */
const isDistanceFar = (distance: number): boolean => {
	if (!distance) {
		return false;
	}

	return distance >= MAX_FAR_DISTANCE;
};

/**
 * Нужно ли открыть выбор города
 */
const needDeterminateTown = (filials: IFilial[]): boolean => {
	if (sessionStorage.getItem(KEYS.PICK_TOWN) === 'true') {
		return false;
	}

	if (!filials.length) {
		return false;
	}

	return (filials[0].address.distance || 0) > WARNING_DISTANCE_LIMIT;
};

/**
 * Скрыть выбор городов
 */
const disableTownPicker = () => {
	sessionStorage.setItem(KEYS.PICK_TOWN, 'true');
};

const NavigatorService = {
	state: readonly(state) as IState,
	allCategories,
	mainCategories,
	setFilials,
	clearAllFilials,
	fetchFilials,
	loadMoreFilials,
	initAllFilials,
	fetchFilialSettings,
	fetchFilial,
	isDistanceFar,
	needDeterminateTown,
	disableTownPicker
};

export { NavigatorService };
export * from './composables';
export * from './functions';
