import { SERVICES, ALL_TOWNS } from '../constants';
import { getQueryByName, isSourceValid } from '../functions';
import { delivery } from '../plugins/delivery';
import { ClientService } from '@/services/client';
import { AuthService } from '@/services/auth';
import { NavigatorService } from '@/services/navigator';
import { SearchService } from '@/services/search';
import { BrandsService } from '@/services/brands';

const initAddresses = async () => {
	if (delivery.contains()) {
		const deliveryService = delivery.service();
		await deliveryService.initUserAddresses(
			AuthService.isAuthorized(),
			ClientService.state.coordinates,
			NavigatorService.clearAllFilials
		);
	}
};

/**
 * useApp
 */
export default function () {
	AuthService.attach(initAddresses);
	ClientService.attachToServiceChange(NavigatorService.clearAllFilials);
	ClientService.attachToServiceChange(SearchService.clearSearchFilials);
	ClientService.attachToServiceChange(BrandsService.clearFilials);

	const initTown = () => {
		const town = getQueryByName('town_id');
		if (town) {
			// назначить временный город в доставке
			const existingTown = ALL_TOWNS.find(t => t.id === town);
			if (existingTown) {
				delivery.service().setTempTown(existingTown.title);
			}

			ClientService.setTownId(+town);
		}
	};

	const initPlatformAndVersion = () => {
		const platform = getQueryByName('platform');
		const version = getQueryByName('v');
		const deviceId = getQueryByName('device_id');

		if (platform) {
			ClientService.setPlatform(platform);
		}
		if (version) {
			ClientService.setVersion(version);
		}
		if (deviceId) {
			ClientService.setDeviceId(deviceId);
		}
	};

	const initService = () => {
		const source = getQueryByName('source');
		if (!source) {
			return;
		}

		// смена сервиса на передаваемый, если имеется сходство
		const availableServices = Object.values(SERVICES).map(s => s.id);
		const serviceId = availableServices.find(s => {
			return isSourceValid(source, s, true);
		});

		ClientService.changeService(serviceId || SERVICES.takeaway.id);
	};

	return {
		initTown,
		initPlatformAndVersion,
		initService
	};
}
