import { client } from '../default.client';
import type { ApiProxyResponse } from '../types/interfaces';
import type { ICourier } from '@/types/interfaces/chocofood';
import type {
	ITariffsPayload,
	IDeliveryTariff
} from '@/types/interfaces/delivery';
import { DELIVERY_TARIFFS } from '@/types/enums/delivery';

interface Response {
	latitude: string;
	longitude: string;
	name: string;
	phone: string;
}

const adapter = (response: ApiProxyResponse<Response>): ICourier => {
	const { latitude, longitude, name, phone } = response.data;

	return {
		id: 1,
		title: name,
		phone: phone,
		latitude: +latitude,
		longitude: +longitude
	};
};

const getMostRelevantTariff = (response: IDeliveryTariff[]) => {
	const fastest = response.filter(tariff => tariff.is_fastest);
	return fastest.length ? fastest : response;
};

export const delivery = {
	async getDeliveryTariffs(
		payload: ITariffsPayload
	): Promise<IDeliveryTariff[]> {
		try {
			const response = await client.post('dr-delivery/api/v1/tariffs', payload);
			return getMostRelevantTariff(response.data.data).sort(
				a => +(a.transport_type === DELIVERY_TARIFFS.FOOT)
			);
		} catch {
			return [];
		}
	},
	async getCourier(orderId: number): Promise<ICourier> {
		const response = await client.get('dr-delivery/api/v1/courier', {
			params: {
				takeaway_order_id: orderId
			}
		});

		return adapter(response.data);
	}
};
