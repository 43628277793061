import { CommonApplication } from './common-application.provider';
import { APP_RUNTIME } from '@/types/enums/common';
import type { TCallback } from '@/utils/plugins/di/types';
import {
	backToApplication,
	applicationBecomeActive,
	handleNavigationToBack,
	whenNavigationToBack,
	cancelHandlingToBack,
	clearCache,
	hapticSelection,
	openQR
} from 'choco-app';

export class WebViewApplication extends CommonApplication {
	constructor() {
		super(APP_RUNTIME.WEB_VIEW);
	}

	override becomingActive(callback: TCallback): void {
		applicationBecomeActive(callback);
	}

	override becomingHidden(): void {
		applicationBecomeActive(() => {});
	}

	override handleNavigationToBack(): void {
		handleNavigationToBack();
	}

	override whenNavigationToBack(cb: TCallback): void {
		whenNavigationToBack(cb);
	}

	override cancelHandlingToBack(): void {
		cancelHandlingToBack();
	}

	override openQR(): Promise<string> {
		return openQR();
	}

	backToApp(): void {
		backToApplication();
	}

	clearCache(): void {
		return clearCache();
	}

	haptic(): void {
		hapticSelection();
	}

	override openExternalLink(url: string): void {
		window.location.href = url;
	}
}
