import { RouteLocationNormalizedLoaded, NavigationGuardNext } from 'vue-router';
import { ClientService } from '@/services/client';
import { application } from '@/utils/plugins/di/providers';
import { delivery } from '@/utils/plugins/delivery';
import { SERVICES } from '@/utils/constants';

export default function (
	to: RouteLocationNormalizedLoaded,
	from: RouteLocationNormalizedLoaded,
	next: NavigationGuardNext
) {
	const config = application.getCustomConfig();

	// Если это вебвью, не показывать доставку
	if (application.isWebView() && delivery.isDefault()) {
		ClientService.changeService(config?.services[0] || SERVICES.takeaway.id);
	}

	return next();
}
