import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import { stringify } from 'qs';
import { v4 as uuidv4 } from 'uuid';
import { useNetwork } from 'choco-miniapp-debugbar';
import i18n from '@/utils/plugins/i18n';
import {
	ApiProxyResponse,
	isApiProxyResponse
} from './types/interfaces/api-proxy-response-data';
import { isApiProxyErrorData } from './types/interfaces/api-proxy-error-data';
import { AUTH_REQUEST, GET_TRACK_ID_REQUEST } from './constants';

type SentryApiType = 'error' | 'warning';

interface AuthProvider {
	isAuthorized: () => boolean;
	getAccessToken: () => string;
	setAccessToken: (token: string) => void;
	goToOauth: () => void;
	logout: () => void;
}

interface SentryProvider {
	captureApiException: (error: any, type: SentryApiType) => void;
}

interface ClientProvider {
	isWebView: () => boolean;
}

interface providers {
	application: {
		clearUserId: () => void;
	};
	auth: {
		authorize: () => Promise<void | boolean>;
	};
}

const TRACKED_APIS: [string, SentryApiType][] = [
	[AUTH_REQUEST, 'warning'],
	[GET_TRACK_ID_REQUEST, 'error']
];

const baseURL = import.meta.env.VITE_API_PROXY;
const { addRequest, addRequestResponseById } = useNetwork();

const client = axios.create({
	baseURL,
	paramsSerializer: params => stringify(params),
	transformRequest: [data => JSON.stringify(data)]
});

axiosRetry(client, {
	retries: 3,
	retryDelay: retryCount => {
		return 1000 * retryCount;
	}
});

const setupInterceptors = (
	authProvider: AuthProvider,
	sentryProvider: SentryProvider,
	clientProvider: ClientProvider,
	providers: providers
) => {
	const captureApiException = (error: any) => {
		for (const [req, type] of TRACKED_APIS) {
			if (req.includes(error.response?.config?.url || '')) {
				sentryProvider.captureApiException(error, type);
				break;
			}
		}
	};

	const logout = (): void => {
		authProvider.logout();
		window.location.reload();
	};

	const handleUnauthorizedResponse = (
		client: AxiosInstance,
		response: AxiosResponse | AxiosError
	): Promise<AxiosResponse<any, any>> => {
		return new Promise(resolve => {
			const appEnv = import.meta.env.VITE_ENV;
			if (appEnv === 'local') {
				const newToken = prompt('Новый токен');

				if (newToken) {
					authProvider.setAccessToken(newToken);
					return resolve(client({ ...response.config }));
				}
			}

			if (
				Object.prototype.hasOwnProperty.call(response.config, '_retryAttempts')
			) {
				return logout();
			}

			const config = { ...response.config, _retryAttempts: 1 };

			if (clientProvider.isWebView()) {
				return providers.auth
					.authorize()
					.then(() => {
						resolve(client(config));
					})
					.catch(() => {
						logout();
					});
			}

			authProvider.goToOauth();
		});
	};

	client.interceptors.request.use(
		config => {
			const fingerprint = uuidv4();
			config.headers['X-Fingerprint'] = fingerprint;
			config.headers['X-Language'] = i18n.global.locale.value;

			if (!config.headers['Content-Type']) {
				config.headers['Content-Type'] = 'application/json';
			}

			if (!config.headers['X-Idempotency-key']) {
				config.headers['X-Idempotency-key'] = uuidv4();
			}

			if (authProvider.isAuthorized()) {
				// eslint-disable-next-line no-param-reassign
				config.headers.Authorization = `Bearer ${authProvider.getAccessToken()}`;
			}

			addRequest({
				id: fingerprint,
				headers: config.headers,
				params: config.params,
				body: config.data,
				url: config.url ? `${baseURL}/${config.url}` : baseURL,
				method: config.method || 'GET'
			});

			return config;
		},
		error => Promise.reject(error)
	);

	client.interceptors.response.use(
		response => {
			const fingerprint = response.config.headers['X-Fingerprint'];
			if (fingerprint) {
				addRequestResponseById(fingerprint, response.status, response.data);
			}

			const data: ApiProxyResponse<any> | any = response.data;

			// gateway
			if (isApiProxyResponse<unknown>(data)) {
				if (data.error_code && data.error_code !== 200) {
					captureApiException(response);

					if (data.error_code === 401) {
						return handleUnauthorizedResponse(client, response);
					}

					return Promise.reject(data);
				}
			}

			return response;
		},
		(error: Error | AxiosError) => {
			if (axios.isAxiosError(error)) {
				const fingerprint = error.config?.headers['X-Fingerprint'];
				if (fingerprint) {
					addRequestResponseById(
						fingerprint,
						error.status || 404,
						error.response?.data
					);
				}

				// назначаю message первой ошибки в ApiProxy
				const data = error.response?.data;
				if (isApiProxyErrorData(data) && data.errors?.length) {
					error.message = data.errors[0].title;
				} else if (isApiProxyResponse<unknown>(data)) {
					error.message = data.message;
				}

				// обработка 401
				const statusCode = error.response?.status || 0;
				if (statusCode === 401) {
					return handleUnauthorizedResponse(client, error);
				}

				captureApiException(error);
			}

			return Promise.reject(error);
		}
	);
};

export { client, setupInterceptors };
