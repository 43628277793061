import { client } from '../default.client';
import { getAddressAdapter } from '../adapters/user';
import type { ApiProxyJSONApiResponse } from '../types/interfaces';
import dayjs from 'dayjs';
import type { ICfUserAddress } from '@/types/interfaces/chocofood';
import type {
	IUser,
	IUserAddress,
	IUserPayload
} from '@/types/interfaces/user';

const getUserAdapter = (
	res: ApiProxyJSONApiResponse<IUser>
): ApiProxyJSONApiResponse<IUser> => {
	res.attributes.bday = res.attributes.bday
		? dayjs(res.attributes.bday, 'YYYY-MM-DD').format('DD/MM/YYYY')
		: '';
	return res;
};

const getBinary = (file: File): Promise<ArrayBuffer> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = () => {
			resolve(reader.result as ArrayBuffer);
		};

		reader.onerror = reject;

		reader.readAsArrayBuffer(file);
	});
};

export const user = {
	async get() {
		const response = await client.get(`api/v3/user`);
		return getUserAdapter(response.data.data);
	},
	async createAddress(address: ICfUserAddress) {
		const payload: Omit<IUserAddress, 'id'> = {
			latitude: address.coordinates.latitude,
			longitude: address.coordinates.longitude,
			comment: address.comment || '',
			entrance: address.entrance || '',
			floor: address.floor || '',
			apartment: address.apartment || '',
			building: address.building || '',
			street: address.street || '',
			city: address.city || '',
			title: address.title || ''
		};

		const response = await client.post('profile/api/v1/address', payload);
		return getAddressAdapter(response.data.data);
	},
	async getUserAddress(id: number) {
		const response = await client.get<ApiProxyJSONApiResponse<IUserAddress>>(
			`profile/api/v1/address/${id}`
		);
		return response.data;
	},
	async getUserAddresses(): Promise<ICfUserAddress[]> {
		const response = await client.get('profile/api/v1/address');

		return response.data.data.map((el: ApiProxyJSONApiResponse<IUserAddress>) =>
			getAddressAdapter(el)
		);
	},
	async deleteAddress(id: number) {
		const response = await client.delete(`profile/api/v1/address/${id}`);
		return response.data;
	},
	async postAvatar(image: File) {
		const binaryImage = await getBinary(image);
		const response = await client.post(`api/v3/user/avatar`, binaryImage, {
			headers: {
				'Content-Type': image.type
			},
			transformRequest: [data => data]
		});
		return response.data;
	},
	async deleteAvatar() {
		const response = await client.delete(`api/v3/user/avatar`);
		return response.data;
	},
	async updateUser(payload: IUserPayload) {
		payload.bday = payload.bday
			? dayjs(payload.bday, 'DD/MM/YYYY').format('YYYY-MM-DD')
			: '';

		const response = await client.put('api/v3/user', {
			data: {
				attributes: payload,
				type: 'user'
			}
		});
		return response.data;
	},
	async updateUserAddress(payload: ICfUserAddress): Promise<ICfUserAddress> {
		const response = await client.put(
			`profile/api/v1/address/${payload.id}`,
			payload
		);
		return getAddressAdapter(response.data.data);
	}
};
