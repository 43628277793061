import { events } from './enums';
import { init, track, setUserId } from '@amplitude/analytics-browser';
import { logAmplitudeEvent } from 'choco-app';
import { CLIENT_ID, NATIVE_APP_AGENT } from '@/utils/constants';
import { application } from '@/utils/plugins/di/providers';
import { TABLET_SESSION, useSession } from '@/utils/composables/use-session';

export interface AnalyticsPayload {
	project_id: string;
	project_name: string;
	project_type: 'web' | 'miniapp' | 'partnerapp';
	service_name: string;
	[key: string]: any;
}

interface AuthProvider {
	getUserId: () => string | null;
}

interface ClientProvider {
	getServiceId: () => string;
}

export class Analytics {
	private isLoggable: boolean;
	private isDev: boolean;
	private isWebView: boolean;
	private key: string;
	private AuthProvider: AuthProvider;
	private ClientProvider: ClientProvider;

	constructor(AuthProvider: AuthProvider, ClientProvider: ClientProvider) {
		this.key = import.meta.env.VITE_AMPLITUDE_KEY;
		this.AuthProvider = AuthProvider;
		this.ClientProvider = ClientProvider;
		this.isWebView = application.isWebView();
		this.isLoggable = import.meta.env.VITE_ENV !== 'production';
		this.isDev = import.meta.env.VITE_ENV === 'local';
		this.init();
	}

	private init() {
		init(this.key, { autocapture: false });
	}

	private getProjectType(): 'web' | 'miniapp' | 'partnerapp' {
		if (this.isWebView) return 'miniapp';
		if (navigator.userAgent.includes(NATIVE_APP_AGENT)) return 'partnerapp';
		return 'web';
	}

	async logEvent(
		event: events,
		customPayload: Record<string, any> = {}
	): Promise<void> {
		const projectType = this.getProjectType();
		const basePayload: AnalyticsPayload = {
			project_id: CLIENT_ID,
			project_name: 'digital restaurant',
			project_type: projectType,
			service_name: this.ClientProvider.getServiceId(),
			...customPayload
		};

		const { getSession } = useSession(TABLET_SESSION);

		if (this.isLoggable) {
			console.log(event, basePayload);
		}

		if (this.isDev) return;

		if (this.isWebView) {
			logAmplitudeEvent(event, basePayload);
			return;
		}

		const userId = this.AuthProvider.getUserId();
		const customSessionId = getSession();
		if (customSessionId) {
			setUserId(customSessionId);

			if (userId) {
				basePayload.user_id = userId;
			}
		} else {
			setUserId(userId || undefined);
		}

		track(event, basePayload);
	}
}

export { events };
