import type { ApiProxyJSONApiResponse } from '../../types/interfaces';
import type { ICfUserAddress } from '@/types/interfaces/chocofood';
import type { IUserAddress } from '@/types/interfaces/user';

export default function (
	address: ApiProxyJSONApiResponse<IUserAddress>
): ICfUserAddress {
	return {
		...address.attributes,
		coordinates: {
			latitude: +address.attributes.latitude,
			longitude: +address.attributes.longitude
		},
		id: +address.id
	};
}
