import { RouteLocationNormalizedLoaded, NavigationGuardNext } from 'vue-router';
import { application } from '@/utils/plugins/di/providers';
import { ClientService } from '@/services/client';
import { AppService } from '@/services/app';

export default function (
	to: RouteLocationNormalizedLoaded,
	from: RouteLocationNormalizedLoaded,
	next: NavigationGuardNext
) {
	const config = application.getCustomConfig();
	if (!config) {
		return next();
	}

	if (!config.services.includes(ClientService.state.service.id)) {
		ClientService.changeService(AppService.getDefaultServiceId());
	}

	return next();
}
