import { PROMO_CATEGORY, FREQUENT_CATEGORY } from '../constants';
import i18n from '@/utils/plugins/i18n';
import type { IProductsCategory } from '@/types/interfaces/menu';

const LOCAL_CATEGORIES = [PROMO_CATEGORY, FREQUENT_CATEGORY];

const findLocalCategory = (category: IProductsCategory) => {
	return LOCAL_CATEGORIES.find(c => c.id === category.id);
};

/**
 * Актуальные переводы локальных категорий
 */
export default function (categories: IProductsCategory[]) {
	return categories.map(c => {
		const localCategory = findLocalCategory(c);
		if (localCategory) {
			c.attributes.name = i18n.global.t(localCategory.translateKey);
		}

		return c;
	});
}
