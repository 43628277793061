import type { ICustomCategory, ITempFilialData } from '../types';

export const PROMO_CATEGORY: ICustomCategory = {
	id: -1,
	translateKey: 'menu.promo'
};

export const FREQUENT_CATEGORY: ICustomCategory = {
	id: -2,
	translateKey: 'menu.myFavorites'
};

export const PROMO_UNLIMITED_NUMBER = 9999;
export const PROMO_LIMIT_NOTIFICATION = 'menu.productsExceeded';
export const PROMO_CHANGED_NOTIFICATION = 'menu.productsPromoChanged';

export const DEFAULT_TEMP_DATA: ITempFilialData = {
	source: '',
	filialId: 0,
	table: undefined
};

export const NO_LOCKER = 'NO_LOCKER';
export const TABLET_OVERLAY_KEY = 'hasShownTabletOverlay';
