<template>
	<button
		class="location-icon h-6 w-6 shrink-0 bg-contain bg-center bg-no-repeat"
		@click.stop="goToMap"
	></button>
</template>

<script>
import { useRouter } from 'vue-router';
import { ClientService } from '@/services/client';

/**
 * Кнопка, которая переходит на страницу карты
 * @module components/map/MapButtonLink
 */
export default {
	name: 'MapButtonLink',
	setup() {
		const router = useRouter();

		/**
		 * Переход к карте, если координаты доступны
		 */
		const goToMap = () => {
			ClientService.doIfCoordinatesExist(() => router.push({ name: 'Map' }));
		};

		return {
			goToMap
		};
	}
};
</script>

<style lang="scss">
.location-icon {
	background-image: url('@/assets/icons/location-redesign.svg');
}
</style>
