import type { IPaginationWrapper } from '@/types/interfaces/common';

export default class<T> implements IPaginationWrapper<T> {
	page = 1;
	lastPage = 1;
	total = 0;
	results: T[] = [];

	addResults(data: IPaginationWrapper<T>): void {
		this.page = data.page;
		this.lastPage = data.lastPage;
		this.total = data.total;
		this.results = this.results.concat(data.results);
	}

	clearResults(): void {
		this.page = 1;
		this.lastPage = 1;
		this.total = 0;
		this.results = [];
	}

	hasResults(): boolean {
		return !!this.results.length;
	}
}
