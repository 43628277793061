import { ClientService } from '@/services/client';
import { DeliveryService } from '@/services/delivery';
import { SERVICES, VISIBLE_TOWNS } from '@/utils/constants';
import { computed } from 'vue';

const useDeliveryAddress = () => {
	const activeTown = computed(() => {
		return (
			VISIBLE_TOWNS.find(
				town => town.title === DeliveryService.state.tempAddress.city
			) || VISIBLE_TOWNS[0]
		);
	});

	const activeAddressCoordinates = computed(() => {
		if (DeliveryService.hasAvailableAddress()) {
			return DeliveryService.state.activeAddress.coordinates;
		}

		return ClientService.state.coordinates || activeTown.value.coordinates;
	});

	const getRelevantCoordinates = () => {
		if (ClientService.state.service.id === SERVICES.delivery.id) {
			return activeAddressCoordinates.value;
		}

		return ClientService.state.coordinates;
	};

	return {
		activeTown,
		activeAddressCoordinates,
		getRelevantCoordinates
	};
};

export default useDeliveryAddress;
