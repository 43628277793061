import { RouteLocationNormalizedLoaded, NavigationGuardNext } from 'vue-router';
import { AuthService } from '@/services/auth';
import { ClientService } from '@/services/client';
import { delivery } from '@/utils/plugins/delivery';
import { application } from '@/utils/plugins/di/providers';
import { NavigatorService } from '@/services/navigator';

export default function (
	to: RouteLocationNormalizedLoaded,
	from: RouteLocationNormalizedLoaded,
	next: NavigationGuardNext
) {
	if (delivery.contains()) {
		const deliveryService = delivery.service();

		if (!deliveryService.hasAvailableAddress()) {
			application.showLoader();

			return delivery
				.service()
				.initUserAddresses(
					AuthService.isAuthorized(),
					ClientService.state.coordinates,
					NavigatorService.clearAllFilials
				)
				.then(() => next())
				.finally(() => application.hideLoader());
		}
	}

	return next();
}
