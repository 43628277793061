<template>
	<Transition name="bottom-sheet">
		<div v-if="isActive" class="bottom-sheet-modal">
			<div
				class="blackout bottom-sheet-overlay"
				@touchstart.passive="onBlackoutTouchStart"
				@touchend="onBlackoutTouchEnd"
			></div>

			<div class="sheet" style="max-height: 95%">
				<div
					aria-modal="true"
					role="dialog"
					class="sheet-shift viewport-max-width"
					:style="{ transform: `translateY(${sheetShift}px)` }"
					@touchstart.passive="onSheetTouchStart"
					@touchmove.passive="onSheetTouchMove"
					@touchend="onSheetTouchEnd"
				>
					<div
						class="shift-content"
						:style="shiftColor ? { backgroundColor: shiftColor } : {}"
					></div>

					<div ref="content" class="bottom-sheet-content">
						<div
							v-if="$slots.title || closeable || $slots.pre"
							class="bottom-sheet-heading"
						>
							<div class="basis-9">
								<slot name="pre"></slot>
							</div>

							<div
								v-if="$slots.title"
								class="ml-auto mr-auto font-sirius text-lg font-black"
							>
								<slot name="title"></slot>
							</div>

							<div class="flex justify-end basis-9">
								<button
									v-if="closeable"
									data-testid="bottom-sheet-close-btn"
									@click="hide"
								>
									<img
										src="@/assets/icons/cross-dark.svg"
										alt=""
										width="24"
										height="24"
									/>
								</button>
							</div>
						</div>

						<slot></slot>
					</div>
				</div>
			</div>
		</div>
	</Transition>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { injectStrict } from '@/utils/functions';
import { ProvidersKey } from '@/types/symbols';

const extractTouch = (event: TouchEvent) => event.changedTouches[0].clientY;

interface Props {
	shiftMinHeight?: number;
	shiftColor?: string;
	closeable?: boolean;
	swipeable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	shiftMinHeight: 70,
	shiftColor: '',
	closeable: false,
	swipeable: true
});
const emit = defineEmits<{ (e: 'opened'): void; (e: 'closed'): void }>();

const isActive = ref(false);
const sheetShift = ref<number>(0);
const content = ref<HTMLElement | null>(null);
const providers = injectStrict(ProvidersKey);
let isBlackoutTouchStarted = false;
let isSheetTouchStarted = false;
let sheetTouchStart = 0;

/**
 * Открыть bottom sheet
 */
const open = () => {
	if (isActive.value) {
		return;
	}

	isActive.value = true;
	providers.application.handleNavigationToBack();
	providers.application.whenNavigationToBack(hide);
	emit('opened');
};

/**
 * Закрыть bottom sheet
 */
const hide = () => {
	if (!isActive.value) {
		return;
	}

	isActive.value = false;
	providers.application.cancelHandlingToBack();
	emit('closed');
};

/**
 * При начале touch на blackout
 */
const onBlackoutTouchStart = () => (isBlackoutTouchStarted = true);

/**
 * При окончании touch на blackout
 */
const onBlackoutTouchEnd = () => {
	if (isBlackoutTouchStarted && props.swipeable) {
		isBlackoutTouchStarted = false;
		hide();
	}
};

/**
 * При начале touch на sheet
 */
const onSheetTouchStart = (event: TouchEvent) => {
	if (content.value && props.swipeable) {
		isSheetTouchStarted = content.value.scrollTop === 0;
		sheetTouchStart = extractTouch(event);
	}
};

/**
 * При передвигании sheet
 */
const onSheetTouchMove = (event: TouchEvent) => {
	if (isSheetTouchStarted) {
		const shift = extractTouch(event) - sheetTouchStart;
		sheetShift.value = Math.max(0, shift);
	}
};

/**
 * При окончании touch на sheet
 */
const onSheetTouchEnd = () => {
	const shift = parseInt(String(sheetShift.value), 10);

	if (isSheetTouchStarted && shift >= props.shiftMinHeight) {
		hide();
	}

	isSheetTouchStarted = false;
	sheetShift.value = 0;
};

defineExpose({
	open,
	hide,
	isActive
});
</script>

<style lang="scss" scoped>
@mixin inset-0 {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.z-9999 {
	z-index: 9999;
}

.blackout {
	@include inset-0;
	@extend .z-9999;
}

.sheet {
	@extend .z-9999;
	position: absolute;
	bottom: 0;
	display: flex;
	width: 100%;

	&-shift {
		display: flex;
		flex-direction: column;
		width: 100%;
		overflow: hidden;
		transition-property: transform;
		transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
		transition-duration: 300ms;
	}
}

.shift-content {
	position: absolute;
	z-index: 9;
	top: 0;
	left: 50%;
	width: 72px;
	height: 6px;
	margin: 0.5rem 0;
	background-color: #d4d3df;
	border-radius: 2.5rem;
	opacity: 0.4;
	transform: translateX(-50%);
}

.bottom-sheet-heading {
	position: sticky;
	z-index: 99;
	top: 0;
	right: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 2rem 1rem 0.5rem;
	background: #fff;
}

.bottom-sheet {
	&-enter-active,
	&-leave-active {
		transition: 500ms;
	}

	&-enter-active > .blackout,
	&-leave-active > .blackout {
		transition: 500ms;
	}

	&-enter-from > .blackout,
	&-leave-to > .blackout {
		opacity: 0;
	}

	&-enter-active > .sheet,
	&-leave-active > .sheet {
		transition: 200ms ease-in;
	}

	&-enter-from > .sheet,
	&-leave-to > .sheet {
		transform: translateY(100%);
	}

	&-overlay {
		background: rgba(15, 15, 17, 0.15);
		backdrop-filter: blur(9px);
	}

	&-modal {
		@include inset-0;
		@extend .z-9999;
		display: flex;
	}

	&-content {
		max-height: 100vh;
		overflow-x: hidden;
		overflow-y: auto;
		overscroll-behavior: none;
		isolation: isolate;
		background: #fff;
		border-top-left-radius: 1.5rem;
		border-top-right-radius: 1.5rem;
	}
}
</style>
