import { RouteLocationNormalizedLoaded, NavigationGuardNext } from 'vue-router';
import { application } from '@/utils/plugins/di/providers';

export default function (
	to: RouteLocationNormalizedLoaded,
	from: RouteLocationNormalizedLoaded,
	next: NavigationGuardNext
) {
	const config = application.getCustomConfig();
	if (config) {
		return next({ name: 'Brands' });
	}

	return next();
}
