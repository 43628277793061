export enum ADDITIVE_TYPES {
	optional = 1,
	required = 2
}

export enum ADDITIVE_STATUSES {
	DEFAULT = 'default',
	FAVOURITE = 'favourite'
}

export enum PROMO_TYPES {
	FULL_MENU = 'full_menu',
	SEPARATE_POSITION = 'separate_position'
}
