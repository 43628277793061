import { client } from '../default.client';
import type { ApiProxyJSONApiResponse } from '../types/interfaces';
import type { AxiosResponse } from 'axios';

interface Profile {
	user_id: number;
	language: string;
}

export const profile = {
	async getUserProfile(): Promise<
		AxiosResponse<ApiProxyJSONApiResponse<Profile>>
	> {
		const response = await client.get('profile/api/v1/profile');
		return response.data;
	},
	async setProfileLanguage(language: string): Promise<void> {
		const response = await client.post('profile/api/v1/profile/language', {
			language
		});

		return response.data;
	}
};
