<template>
	<div
		class="search-field font-sirius flex items-center rounded-2xl bg-white px-3"
	>
		<span
			class="search-icon h-6 w-6 shrink-0 bg-contain bg-center bg-no-repeat"
		></span>

		<div class="flex flex-1 items-center px-1">
			<input
				ref="input"
				:value="modelValue"
				type="text"
				class="search-input h-10 flex-1 text-base leading-none"
				data-testid="search-input"
				:placeholder="placeholder"
				:disabled="disabled"
				@focus="$emit('focus')"
				@input="$emit('update:modelValue', $event.target.value.trim())"
				@keyup.enter="submitInput"
			/>

			<button
				v-show="modelValue"
				class="cross-icon block h-6 w-6 bg-contain bg-center bg-no-repeat"
				data-testid="search-reset"
				@click="resetInput"
			></button>
		</div>

		<!-- Контент -->
		<slot></slot>
	</div>
</template>

<script>
import { ref } from 'vue';

/**
 * Поле для ввода текста поиска
 * @module components/search/SearchField
 */
export default {
	name: 'SearchField',
	props: {
		modelValue: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: 'Найти что-угодно'
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	emits: ['update:modelValue', 'focus', 'input', 'submit'],
	setup(props, { emit, expose }) {
		const input = ref(null);

		const focusInput = () => {
			if (input.value) {
				input.value.focus();
			}
		};

		const blurInput = () => {
			if (input.value) {
				input.value.blur();
			}
		};

		const submitInput = () => {
			emit('submit');
			blurInput();
		};

		const resetInput = () => {
			emit('update:modelValue', '');
			focusInput();
		};

		expose({ focusInput, blurInput });

		return {
			input,
			focusInput,
			blurInput,
			submitInput,
			resetInput
		};
	}
};
</script>

<style lang="scss" scoped>
.search {
	&-field {
		box-shadow:
			0px 92px 92px rgba(0, 0, 0, 0.05),
			0px 3px 38.4354px rgba(0, 0, 0, 0.0334858),
			0px -3px 12px rgba(0, 0, 0, 0.06),
			0px 5.79369px 11.5198px rgba(0, 0, 0, 0.0189792),
			0px 2.40368px 6.11809px rgba(0, 0, 0, 0.0132742),
			0px 0.705169px 2.54588px rgba(0, 0, 0, 0.00743532);
	}

	&-icon {
		background-image: url('@/assets/icons/search-redesign.svg');
	}

	&-input::placeholder {
		color: #a4a2b7;
	}
}

.cross-icon {
	background-image: url('@/assets/icons/cross-redesign.svg');
}
</style>
