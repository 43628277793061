import type {
	IGetReverseGeocodingResponse,
	IReverseGeocoding
} from '@/types/interfaces/chocofood';

export default function (
	response: IGetReverseGeocodingResponse[]
): IReverseGeocoding[] {
	return response.map(el => ({
		...el.address,
		coordinates: {
			latitude: el.geometry.location.lat,
			longitude: el.geometry.location.long
		},
		street: el.address.street || el.address.district
	}));
}
