import type { ApiProxyResponse } from '@/api/types/interfaces/api-proxy-response-data';
import type { IPaginationWrapper } from '@/types/interfaces/common';

/**
 * Для преобразования ответа пагинации
 */
export default function (
	response: ApiProxyResponse<any>
): IPaginationWrapper<any> {
	return {
		results: response.data,
		page: response.meta?.current_page || 1,
		lastPage: response.meta?.last_page || 1,
		total: response.meta?.total || 0
	};
}
