import { client } from '../default.client';
import type { ApiProxyResponse } from '../types/interfaces';
import type {
	BrandFilial,
	FilialsByBrandNameHash,
	IDeliveryZoneResponse
} from '@/types/interfaces/brands';
import type { ICoordinates } from '@/types/interfaces/common';

interface FilialsByBrandPayload {
	brandId: string;
	townId: number;
	coordinates?: ICoordinates | null;
	page?: number;
	limit?: number;
}

const getBrandNameHash = (filials: BrandFilial[]): FilialsByBrandNameHash => {
	const hash: FilialsByBrandNameHash = {};

	filials.forEach(filial => {
		if (!hash[filial.brand_name]) {
			hash[filial.brand_name] = [];
		}

		hash[filial.brand_name].push(filial);
	});

	return hash;
};

export const brandy = {
	async getFilialsByBrand(payload: FilialsByBrandPayload) {
		const response = await client.get<ApiProxyResponse<BrandFilial[]>>(
			`/brandy/api/v1/locations/brand/${payload.brandId}`,
			{
				headers: {
					'X-Latitude': payload.coordinates?.latitude,
					'X-Longitude': payload.coordinates?.longitude
				},
				params: {
					town_id: payload.townId,
					page: payload.page || 1,
					limit: payload.limit || 20
				}
			}
		);

		return getBrandNameHash(response.data.data);
	},
	async getInDeliveryZone(url: string, coordinates: ICoordinates) {
		return client
			.get<ApiProxyResponse<IDeliveryZoneResponse>>(url, {
				headers: {
					'X-Latitude': coordinates.latitude,
					'X-Longitude': coordinates.longitude
				}
			})
			.then(response => response.data)
			.catch(error => {
				throw error;
			});
	},
	getInDeliveryZoneByBrand(brandId: string, coordinates: ICoordinates) {
		return this.getInDeliveryZone(
			`brandy/api/v1/brands/${brandId}/delivery-zones/check`,
			coordinates
		);
	},
	getInDeliveryZoneByLocation(locationId: string, coordinates: ICoordinates) {
		return this.getInDeliveryZone(
			`brandy/api/v1/locations/${locationId}/delivery-zones/check`,
			coordinates
		);
	}
};
