import kzFlag from '@/assets/icons/kz-flag.svg';
import ruFlag from '@/assets/icons/ru-flag.svg';
import enFlag from '@/assets/icons/en-flag.svg';
import kaFlag from '@/assets/icons/ka-flag.svg';
import kk from '@/assets/locales/kk.json';
import ru from '@/assets/locales/ru.json';
import en from '@/assets/locales/en.json';
import ka from '@/assets/locales/ka.json';

export const DEFAULT_LANGUAGES = [
	{
		name: 'Қазақ тілі',
		code: 'kk',
		label: 'Қаз',
		icon: kzFlag,
		messages: kk
	},
	{
		name: 'Русский язык',
		code: 'ru',
		label: 'Рус',
		icon: ruFlag,
		messages: ru
	},
	{
		name: 'English',
		code: 'en',
		label: 'Eng',
		icon: enFlag,
		messages: en
	}
] as const;

const ALL_LANGUAGES = [
	...DEFAULT_LANGUAGES,
	{
		name: 'ქართული',
		code: 'ka',
		label: 'ქა',
		icon: kaFlag,
		messages: ka
	}
] as const;

export default ALL_LANGUAGES;
