import { events } from '@/services/analytics';
import { analytics } from '@/utils/analytics-factory';

interface IConfirmSearchPayload {
	query: string;
}

interface ISearchAnalytics {
	trackOnConfirmSearch: (payload: IConfirmSearchPayload) => void;
}

const searchAnalytics: () => ISearchAnalytics = () => ({
	trackOnConfirmSearch: (payload: IConfirmSearchPayload) => {
		analytics.logEvent(events.SEARCH_CONFIRM, payload);
	}
});

export default searchAnalytics;
