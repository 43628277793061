import { RouteLocationNormalizedLoaded, NavigationGuardNext } from 'vue-router';
import { NavigatorService } from '@/services/navigator';
import { useDeliveryAddress } from '@/composables/delivery';
import { application } from '@/utils/plugins/di/providers';
import { ICoordinates } from '@/types/interfaces/common';

const fetchIsPullingEnabled = (
	filialId: number,
	coordinates: ICoordinates | null
) => {
	return NavigatorService.fetchFilial(filialId, coordinates).then(
		data => data.filial.is_check_pulling_enabled
	);
};

export default async function (
	to: RouteLocationNormalizedLoaded,
	from: RouteLocationNormalizedLoaded,
	next: NavigationGuardNext
) {
	const filialId = +(to.query.filial_id || '');
	const { table_id: tableId, main_terminal_id: mainTerminalId } = to.query;
	const { getRelevantCoordinates } = useDeliveryAddress();

	if (tableId && filialId && mainTerminalId) {
		try {
			application.showLoader();

			const isCheckPullingEnabled = await fetchIsPullingEnabled(
				filialId,
				getRelevantCoordinates()
			);

			if (isCheckPullingEnabled) {
				next({
					name: 'Bill',
					params: {
						filial_id: filialId
					},
					query: to.query
				});
			} else {
				next();
			}
		} catch (error) {
			next();
		} finally {
			application.hideLoader();
		}
	} else {
		next();
	}
}
