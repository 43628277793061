import type { ICoordinates } from '@/types/interfaces/common';
import type {
	IAddressSuggestion,
	ICfUserAddress,
	IReverseGeocoding
} from '@/types/interfaces/chocofood';
import type { IDeliveryTariff } from '@/types/interfaces/delivery';
import type { Subsidy } from '@/types/interfaces/menu';
import type { IDeliveryZoneResponse } from '@/types/interfaces/brands';
import type { ITown } from '@/types/interfaces/common/address';

export interface IState {
	tempAddress: ICfUserAddress;
	activeAddress: ICfUserAddress;
	userAddressList: ICfUserAddress[];
}

export interface IBaseDeliveryService {
	fetchAddressSuggestions: (
		address: string,
		town?: ITown
	) => Promise<IAddressSuggestion[]>;
	fetchAddressByCoordinates: (
		coordinates: ICoordinates
	) => Promise<IReverseGeocoding[]>;
	isPrivateResidence: () => boolean;
	setTempTown: (town: string) => void;
	getAddressString: (
		address: ICfUserAddress,
		keys?: Array<keyof ICfUserAddress>
	) => string;

	hasAvailableAddress: () => boolean;
	getRedirectUrl: () => string;
	setRedirectUrl: (url: string) => void;
	clearRedirectUrl: () => void;
}

export interface DeliveryZonePayload {
	brandId?: string;
	locationId?: string;
	coordinates: ICoordinates;
}

export interface IDeliveryService extends IBaseDeliveryService {
	state: IState;
	setTempAddress: (address: Partial<ICfUserAddress>) => void;
	setActiveAddress: (address: ICfUserAddress) => void;
	clearTempAddress: () => void;
	clearActiveAddress: () => void;
	createAddress: (
		address: ICfUserAddress,
		isAuthorized: boolean,
		onClearCb: () => void
	) => Promise<ICfUserAddress | undefined>;
	editUserAddress: (
		id: number,
		address: ICfUserAddress,
		isAuthorized: boolean
	) => Promise<void>;
	deleteAddress: (
		id: number,
		isAuthorized: boolean,
		coordinates: ICoordinates | null
	) => Promise<void>;
	fetchUserAddresses: () => Promise<ICfUserAddress[]>;
	initUserAddresses: (
		isAuthorized: boolean,
		coordinates: ICoordinates | null,
		onClearCb: () => void
	) => Promise<void>;
	needToConfirmAddress: () => boolean;
	confirmAddress: () => void;
	fetchTariffs: (
		originCoordinates: ICoordinates,
		destination: ICoordinates
	) => Promise<IDeliveryTariff[]>;
	checkDeliveryZone: (
		payload: DeliveryZonePayload
	) => Promise<IDeliveryZoneResponse>;
	fetchSubsidies: (filialId: number) => Promise<Subsidy>;
	getDeliverySubsidyDiscount: (sum: number, subsidies: Subsidy) => number;
}

export enum KEYS {
	IS_ADDRESS_CONFIRMED_KEY = 'isAddressConfirmed',
	REDIRECT_URL_KEY = 'addressRedirectUrl'
}
