<template>
	<label
		class="radio-container flex items-center font-sirius"
		:class="{
			'flex-row-reverse justify-between': reversal
		}"
	>
		<div class="inline-flex">
			<input
				v-model="model"
				type="radio"
				:name="name"
				:value="value"
				:disabled="disabled"
				@change.stop
				@click.stop="$emit('click')"
			/>

			<span
				class="radio-checkmark"
				:class="[
					{ 'radio-checkmark--disabled': disabled },
					reversal ? 'ml-3' : 'mr-3'
				]"
			></span>
		</div>

		<div :class="{ 'text-grey-light': disabled }">
			<span v-if="label" class="text-sm">{{ label }}</span>

			<!-- Слот -->
			<slot></slot>
		</div>

		<!-- Слот для дополнительных элементов -->
		<slot name="extra"></slot>
	</label>
</template>

<script setup lang="ts">
import { computed } from 'vue';
interface Props {
	label?: string;
	name: string;
	modelValue: unknown;
	value?: any;
	disabled?: boolean;
	reversal?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	label: '',
	value: null,
	disabled: false,
	reversal: false
});

const emit = defineEmits<{
	(e: 'update:modelValue', value: unknown): void;
	(e: 'click'): void;
}>();

const model = computed({
	get() {
		return props.modelValue;
	},
	set(value) {
		emit('update:modelValue', value);
	}
});
</script>

<style lang="scss" scoped>
.radio {
	&-container {
		position: relative;

		span {
			top: 0;
			display: inline-block;
		}

		input[type='radio'] {
			position: absolute;
			width: 0;
			height: 0;
			opacity: 0;
		}
	}

	&-checkmark {
		position: relative;
		width: 24px;
		height: 24px;
		border: 2px solid #e5e5e5;
		padding: 4px;
		border-radius: 50%;

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: none;
			width: 0.75rem;
			height: 0.75rem;
			border-radius: 50%;
			background: var(--primary-color);
		}

		&--disabled {
			opacity: 0.25;
		}
	}

	&-container input[type='radio']:checked ~ &-checkmark {
		border: 2px solid var(--primary-color);

		&:after {
			display: block;
		}
	}
}
</style>
