<template>
	<ul class="px-4">
		<li
			v-for="(value, i) in values"
			:key="i"
			class="border-grey-lite flex items-center border-b border-solid py-3"
			data-testid="history-item"
			@click="$emit('select', value)"
		>
			<span
				class="autocomplete-search mr-2 h-6 w-6 shrink-0 bg-contain bg-no-repeat"
			></span>
			<span
				class="font-sirius text-base"
				data-testid="search-autocomplete-text"
			>
				{{ value }}
			</span>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'SearchAutocomplete',
	props: {
		values: {
			type: Array,
			default: () => []
		}
	},
	emits: ['select']
};
</script>

<style lang="scss" scoped>
.autocomplete-search {
	background-image: url('@/assets/icons/search-redesign.svg');
}
</style>
