import { RouteLocationNormalizedLoaded, NavigationGuardNext } from 'vue-router';
import { ClientService } from '@/services/client';
import { AuthService } from '@/services/auth';
import { auth } from '@/utils/plugins/di/providers';

export default function (
	to: RouteLocationNormalizedLoaded,
	from: RouteLocationNormalizedLoaded,
	next: NavigationGuardNext
) {
	if (AuthService.isAuthorized()) {
		next();
	} else {
		if (ClientService.isWebView()) {
			return auth(AuthService)
				.authorize()
				.then(() => next())
				.catch(() => {
					if (from.matched.length === 0) {
						next({ name: 'Home' });
					} else {
						next(false);
					}
				});
		}

		return AuthService.goToOauth();
	}
}
