import { client } from '../default.client';
import type {
	ApiProxyResponse,
	TelegramUserNotificationResponse
} from '../types/interfaces';

export const telegram = {
	async getNotificationChat() {
		const url = 'telegram-user-notifications/v1/chat';

		const response =
			await client.get<ApiProxyResponse<TelegramUserNotificationResponse>>(url);
		return response.data;
	}
};
