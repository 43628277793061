import { events } from '@/services/analytics';
import { analytics } from '@/utils/analytics-factory';
import type { Locale } from '@/utils/plugins/i18n';

export type LangChangeSources =
	| 'menu'
	| 'tablet_menu'
	| 'tablet_overlay'
	| 'profile'
	| 'billing'
	| 'main';

interface LangChangedPayload {
	source: LangChangeSources;
	lang: Locale;
}

export const useLanguageAnalytics = () => {
	const trackLangChanged = (payload: LangChangedPayload) => {
		analytics.logEvent(events.LANGUAGE_CHANGED, payload);
	};

	return {
		trackLangChanged
	};
};
