import calculateDistance from './calculate-distance';
import { getDefaultAddress } from './get-default-address';
import type { ICoordinates } from '@/types/interfaces/common';
import type { ICfUserAddress } from '@/types/interfaces/chocofood';

export default function (
	userAddressList: ICfUserAddress[],
	userCoordinates: ICoordinates | null
): ICfUserAddress {
	// если есть координаты и список адресов пользователя - считаем расстояние до каждого адреса и выбираем ближайший
	if (userAddressList.length && userCoordinates) {
		const addressDistances = userAddressList.map(address => {
			return calculateDistance(address.coordinates, userCoordinates);
		});

		const minDistance = Math.min(...addressDistances);

		return userAddressList[addressDistances.indexOf(minDistance)];
	}

	// если нет координат, но есть последний использованный адрес - возвращаем его
	if (userAddressList.length) {
		return userAddressList[0];
	}

	// если нет ни координат, ни последнего использованного адреса - возвращаем адрес по умолчанию
	return getDefaultAddress();
}
