<template>
	<UiModal ref="root" :closeable="false">
		<p v-if="isChefsOrMarrone" class="py-8 px-4 text-base font-medium">
			Просим обновить приложение до последней версии, чтобы получить доступ к
			новым функциям
		</p>

		<BaseAction v-else @confirm="onConfirm">
			<template #text>
				{{
					isPartnerApp
						? `Просим обновить приложение до последней версии, чтобы получить доступ к новым функциям`
						: 'Оплатите счет с помощью приложения Mamma Mia и получите 10% кешбэк на первую оплату'
				}}
			</template>
			<template #confirm>
				{{ isPartnerApp ? 'Обновить' : 'Скачать приложение' }}
			</template>
		</BaseAction>
	</UiModal>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import BaseAction from '@/components/base/BaseAction.vue';
import { injectStrict } from '@/utils/functions';
import { NATIVE_APP_AGENT } from '@/utils/constants';
import { ProvidersKey } from '@/types/symbols';

const providers = injectStrict(ProvidersKey);
const version = providers.application.getCustomConfig()?.version;
const isChefsOrMarrone = version && ['1.1.0', '2.1.0'].includes(version);

const root = ref<UiModal | null>(null);
const isPartnerApp = navigator.userAgent.includes(NATIVE_APP_AGENT);

const onConfirm = () => {
	window.open('https://onelink.to/t8sm39', '_blank');
};

onMounted(() => {
	root.value?.open();
});
</script>
